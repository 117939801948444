.e-top-div {
  padding-right:0;
  padding-left:0;
  position: relative;
  max-width: 100%;
}

.e-social-info {
  position: fixed;
  z-index: 100;
  
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: right;
  bottom: 40px;
  right: 40px;
}

.e-icon-wrapper {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  max-width: fit-content;
  float: right;
  padding-left: 10px;
}

.e-social-icon {
  color: grey;
}
.e-social-icon:hover {
  color: #0072b1;
}

@media only screen and (max-width: 1200px) {
  .e-social-info {
    bottom: 10vh;
    right: 20px;
  }
  .e-icon-wrapper {
    max-width: 100%;
    float: none;
    padding-top: 5px;
  }
}

.e-Landing {
  padding-right:0;
  padding-left:0;
  background-image: url(./assets/leaf-background-cropped4.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
  position: relative;
}

.e-landing-name {
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  max-width: 100%;
}

.back-btn {
  border-color: white;
  color: white;
  border-radius: 0;
  /* padding-top: 30px; */
}
.back-btn:hover {
  color: black;
  background-color: white;
  opacity: 80%;
}

.education-title {
  padding-top: 20px;
}

.edu-content {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.education-text {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.course-period {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}
.period-text {
  padding-top: 20px;
}

.course {
  max-width: 100%;
  padding-left: 10px;
  padding-right: 0;
}
.course-title {
  padding-top: 10px;
}