.top-div {
  padding-right:0;
  padding-left:0;
  /* height: 3000px; */
  position: relative;
}

.social-info {
  /* To scroll fix position element: */
  position: fixed;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  z-index: 100;
  
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: right;
  bottom: 40px;
  right: 40px;
}

.icon-wrapper {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  max-width: fit-content;
  float: right;
  padding-left: 10px;
}

.social-icon {
  /* padding-right: 10px; */
  color: grey;
}
.social-icon:hover {
  color: #0072b1;
}

.Landing {
  padding-right:0;
  padding-left:0;
  background-image: url(./assets/leaf-background-cropped4.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}

.landing-name {
  color: white;
  padding-left: 0;
  padding-right: 0;
  /* margin-left: 50px;
  margin-right: 50px; */
  max-width: 100%;
}

.hello-text {
  color: white;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Roboto', sans-serif;
}

.name-text {
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Roboto', sans-serif;
}

.my-desc {
  color: white;
  padding-left: 50px;
  padding-right: 0;
  /* margin-left: 50px; */
  margin-right: 50px;
  /* right: 50px; */
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.down-arrow-div {
  position: absolute;
  bottom: 10vh;

  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.down-arrow {
  background-color: transparent;
  filter: invert(70%);
  width: 100px;
  /*positioning the arrow on the page*/
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.down-arrow:hover {
  filter: invert(100%);
  cursor: pointer;
}

.about-me {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 100%;
  padding-top: 50px;
  /* position: relative; */
}
.about-me-text {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  position: relative;
}
.about-me-title {
  font-family: 'Roboto', sans-serif;
}
.about-me-desc {
  font-family: 'Roboto', sans-serif;
}
.ut-tower {
  padding-left: 10px;
  width: 3rem;
}

.resume-btn-div {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}
.resume-btn {
  margin-left: 10px;
  border-radius: 0px;
}
.resume-btn:hover {
  border: 18px
}

.coursework-btn {
  border-radius: 0px;
}
.coursework-btn:hover {
  border: 18px
}

.hr1 {
  padding-top: 50px;
}

.experience {
  max-width: 100%;
  padding-top: 50px;
  padding-left: 0;
  padding-right: 0;
}
.experience-title {
  padding-left: 50px;
  padding-right: 50px;
}
.experience-text {
  max-width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
}
.experience-desc{
  max-width: 100%;
  padding-left: 0px;
  padding-right: 50px;
  padding-top: 3px;
}

.footer {
  margin-top: 50px;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  /* background-color: #0F3325; */
  background-image: url(./assets/leaf-background-cropped4.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 30vh;
  /* position: absolute; */
  bottom: 0px;
  margin-bottom: 0;
  padding-bottom: 10px;
  position: relative;
}

.copyright {
  bottom: 20px;
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  float: none;
}
.copyright-text1 {
  color: gray;
  /* float: none; */
}
.copyright-text2 {
  color: gray;
  /* float: none; */
  /* clear: left; */
}

/* widths 1880px, 1200px, 600px*/

@media only screen and (max-width: 1200px) {
  .social-info {
    bottom: 10vh;
    right: 20px;
  }
  .icon-wrapper {
    max-width: 100%;
    float: none;
    padding-top: 5px;
  }
  .copyright-text1 {
    font-size: small;
  }
}

/* @media only screen and (max-width: 1200px) {
  .Landing-card {
    top: 100px;
    left: 100px;
  }
  .Landing-card-title {
    font-size: 130px;
  }
  .Landing-card-text {
    font-size: 90px;
  }
}

@media only screen and (max-width: 600px) {
  .Landing-card {
    top: 125px;
    left: 125px;
  }
  .Landing-card-title {
    font-size: 120px;
  }
  .Landing-card-text {
    font-size: 80px;
  }
}

@media only screen and (max-width: 450px) {
  .Landing-card {
    top: 125px;
    left: 125px;
  }
  .Landing-card-title {
    font-size: 120px;
  }
  .Landing-card-text {
    font-size: 80px;
  }
} */
